import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, mobileThresholdPixels } from '../../home/v3/styledComponents';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -22px;
  margin-right: -22px;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    margin: 0px;
  }
`;

const PhotoContainer = styled.div`
  flex: 1;
  margin-left: 22px;
  margin-right: 22px;
  ${({ isCentered }) => isCentered && 'display: flex; justify-content: center;'}
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const Photo = styled.img`
  width: ${({ isSmall }) => isSmall ? '600px' : '100%'};
  ${({ isSmall, hasText }) => !isSmall && `height: ${hasText ? 93 : 100}%;`}
  object-fit: cover;
`;

const Text = styled.div`
  font-family: Libre Baskerville;
  font-size: 14px;
  line-height: 33px;
  color: ${colors.navy};
`;

const Source = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
  color: ${colors.white};
  font-family: Libre Baskerville;
  font-size: 12px;
  line-height: 18px;
`;

const RowPhotos = ({ photos }) => (
  <Container>
    {photos.map(({
      src, alt, text, source,
    }) => (
      <PhotoContainer key={`${src.publicURL}-${text}`} isCentered={photos.length === 1}>
        <RelativeContainer>
          <Photo src={src.publicURL} alt={alt} hasText={!!text} isSmall={photos.length === 1} loading="lazy" />
          {source ? (
            <Source>
              Source:
              {source}
            </Source>
          ) : null}
        </RelativeContainer>
        {text && (
          <Text>
            {' — '}
            {text}
          </Text>
        )}
      </PhotoContainer>
    ))}
  </Container>
);

RowPhotos.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }).isRequired,
    alt: PropTypes.string.isRequired,
    text: PropTypes.string,
    source: PropTypes.string,
  })).isRequired,
};

export default RowPhotos;
