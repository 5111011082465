import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mobileThresholdPixels } from '../components/home/v3/styledComponents';

import LaRubriqueTemplate3TopBlock from '../components/LaRubrique/v3/TopBlock';
import LaRubriqueTemplate3Row from '../components/LaRubrique/v3/Row';
import { Title1 } from '../components/home/homeStyledComponents';
import Breadcrumb from '../components/LaRubrique/v3/Breadcrumb';
import { toFormattedText } from '../services/formatting';
import CTALink from '../components/CTALink';
import { renderBecomeTillisteButton } from '../components/BeTilliste';

const Content = styled.div`
  max-width: 1064px;
  margin: auto;
`;

const RowsContainer = styled.div`
  margin-top: 100px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 60px;
  }
`;

const RowContainer = styled.div`
  margin-bottom: 100px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 60px;
  }
`;

export const Title = styled(Title1)`
  text-transform: uppercase;
  font-family: Libre Baskerville;
  max-width: 850px;
  margin: 0px auto 40px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px auto 20px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const LaRubriquePostTemplate3 = ({ post, preFilledSteps }) => (
  <Content>
    {post.breadcrumb && <Breadcrumb itemListElement={post.breadcrumb} />}
    <Title>{toFormattedText(post.mainTitle)}</Title>
    {post.introduction && <LaRubriqueTemplate3TopBlock {...post} />}
    <RowsContainer>
      {post.rows?.map((row, index) => (
        <React.Fragment key={Math.random()}>
          {index === 2 && (
            <RowContainer>
              <ButtonContainer>
                {post.slug === 'CommentDevenirCouturierEn2021'
                  ? renderBecomeTillisteButton()
                  : <CTALink fromInteraction="LaRubriqueFooter" defaultLabel="Prendre RDV" linkState={preFilledSteps} />}
              </ButtonContainer>
            </RowContainer>
          )}
          <RowContainer>
            <LaRubriqueTemplate3Row {...row} />
          </RowContainer>
        </React.Fragment>
      ))}
    </RowsContainer>
  </Content>
);

LaRubriquePostTemplate3.propTypes = {
  post: PropTypes.shape({
    slug: PropTypes.string,
    rows: PropTypes.arrayOf(PropTypes.shape({})),
    breadcrumb: PropTypes.arrayOf(PropTypes.shape({})),
    mainTitle: PropTypes.string,
    introduction: PropTypes.string,
  }).isRequired,
  preFilledSteps: PropTypes.shape({}),
};

LaRubriquePostTemplate3.defaultProps = {
  preFilledSteps: {},
};

export default LaRubriquePostTemplate3;
