import React from 'react';
import PropTypes from 'prop-types';

import LaRubriqueTemplate2TopBlock from '../components/LaRubrique/LaRubriqueTemplate2TopBlock';
import LaRubriqueTemplate2MiddleBlock from '../components/LaRubrique/LaRubriqueTemplate2MiddleBlock';
import LaRubriqueTemplate2MiddleBlockLongVersion from '../components/LaRubrique/LaRubriqueTemplate2MiddleBlockLongVersion';
import LaRubriqueTemplateAnecdote from '../components/LaRubrique/LaRubriqueTemplateAnecdote';
import LaRubriqueTemplate2BottomBlock from '../components/LaRubrique/LaRubriqueTemplate2BottomBlock';
import LaRubriqueTemplateMadeByBlock from '../components/LaRubrique/LaRubriqueTemplateMadeByBlock';
import { Title } from './LaRubriquePostTemplate3';

const LaRubriquePostTemplate2 = ({ post, preFilledSteps }) => (
  <div>
    <Title>{post.mainTitle}</Title>
    <LaRubriqueTemplate2TopBlock {...post.topBlock} />
    {!post.middleBlock.longVersion ? (
      <LaRubriqueTemplate2MiddleBlock {...post.middleBlock} />
    ) : (
      <LaRubriqueTemplate2MiddleBlockLongVersion
        rows={post.middleBlock.rows}
        adviceBlock={post.middleBlock.adviceBlock}
        lastRow={post.middleBlock.lastRow}
        preFilledSteps={preFilledSteps}
      />
    )}
    {post.anecdote && (
      <LaRubriqueTemplateAnecdote
        tillisteName={post.anecdote.tillisteName}
        tillisteWords={post.anecdote.tillisteWords}
        tillisteImg={
          post.anecdote.tillisteImg && post.anecdote.tillisteImg.publicURL
        }
      />
    )}
    {post.bottomBlock && (
      <LaRubriqueTemplate2BottomBlock
        row4Image={
          post.bottomBlock.row4Image.src
          && post.bottomBlock.row4Image.src.publicURL
        }
        row4ImageAlt={post.bottomBlock.row4Image.alt}
        row4TitleMarginMobile={post.bottomBlock.row4TitleMarginMobile}
        row4Text={post.bottomBlock.row4Text}
        row4TextMargin={post.bottomBlock.row4TextMargin}
      />
    )}
    {post.madeByText && (
      <LaRubriqueTemplateMadeByBlock paragraph={post.madeByText} />
    )}
  </div>
);

LaRubriquePostTemplate2.propTypes = {
  post: PropTypes.shape({
    topBlock: PropTypes.shape({}).isRequired,
    mainTitle: PropTypes.string.isRequired,
    middleBlock: PropTypes.shape({
      longVersion: PropTypes.bool,
      subBlockTitle: PropTypes.string,
      notNumbered: PropTypes.bool,
      titleOnLeft: PropTypes.bool,
      rows: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.shape({
            src: PropTypes.shape({
              publicURL: PropTypes.string.isRequired,
            }),
            srcMobile: PropTypes.shape({
              publicURL: PropTypes.string.isRequired,
            }),
            alt: PropTypes.string,
            caption: PropTypes.shape({
              title: PropTypes.string,
              text: PropTypes.string,
              margin: PropTypes.string,
              marginMobile: PropTypes.string,
              isFlexEnd: PropTypes.bool,
            }),
            isMobileFull: PropTypes.bool,
            margin: PropTypes.string,
            button: PropTypes.shape({
              label: PropTypes.string,
              preFilledStepCloth: PropTypes.string,
            }),
            text: PropTypes.shape({
              label: PropTypes.string,
              fontSize: PropTypes.string,
              fontSizeMobile: PropTypes.string,
              margin: PropTypes.string,
              marginMobile: PropTypes.string,
            }),
          }),
          video: PropTypes.shape({
            src: PropTypes.string,
            width: PropTypes.string,
            height: PropTypes.string,
            margin: PropTypes.string,
            mobileWidth: PropTypes.string,
            mobileHeight: PropTypes.string,
            mobileMargin: PropTypes.string,
          }),
          textIsRight: PropTypes.bool,
          title1: PropTypes.string,
          title1Margin: PropTypes.string,
          title1Mobile: PropTypes.string,
          isTitle1Centered: PropTypes.bool,
          contentMobileMarginBottom: PropTypes.string,
          text1: PropTypes.string,
          text1Links: PropTypes.arrayOf(PropTypes.string),
          text1IsCentered: PropTypes.bool,
          title2: PropTypes.string,
          text2: PropTypes.string,
          isMobileReversed: PropTypes.bool,
          isMobileTitleCentered: PropTypes.bool,
          isDesktopTitleHidden: PropTypes.bool,
          isMobileTitleHidden: PropTypes.bool,
          isMobileTitleInsideBlock: PropTypes.bool,
          mobileTextMargin: PropTypes.string,
          imagesRow: PropTypes.arrayOf(
            PropTypes.shape({
              src: PropTypes.shape({
                publicURL: PropTypes.string,
              }),
              srcMobile: PropTypes.shape({
                publicURL: PropTypes.string,
              }),
              alt: PropTypes.string,
              caption: PropTypes.shape({
                title: PropTypes.string,
                text: PropTypes.string,
                margin: PropTypes.string,
                marginMobile: PropTypes.string,
              }),
              margin: PropTypes.string,
            }),
          ),
        }),
      ).isRequired,
      adviceBlock: PropTypes.shape({
        adviceList: PropTypes.arrayOf(PropTypes.string.isRequired),
        closingText: PropTypes.string.isRequired,
      }),
      lastRow: PropTypes.shape({
        image: PropTypes.shape({
          src: PropTypes.shape({
            publicURL: PropTypes.string.isRequired,
          }),
          alt: PropTypes.string.isRequired,
        }),
        text: PropTypes.string.isRequired,
      }),
    }).isRequired,
    anecdote: PropTypes.shape({
      tillisteName: PropTypes.string.isRequired,
      tillisteWords: PropTypes.string.isRequired,
      tillisteImg: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }),
    }),
    bottomBlock: PropTypes.shape({
      row4Image: PropTypes.shape({
        src: PropTypes.shape({
          publicURL: PropTypes.string.isRequired,
        }).isRequired,
        alt: PropTypes.string.isRequired,
      }).isRequired,
      row4TitleMarginMobile: PropTypes.string,
      row4Text: PropTypes.string.isRequired,
      row4TextMargin: PropTypes.string.isRequired,
    }),
    madeByText: PropTypes.string,
  }).isRequired,
  preFilledSteps: PropTypes.shape({}),
};

LaRubriquePostTemplate2.defaultProps = {
  preFilledSteps: {},
};

export default LaRubriquePostTemplate2;
