import React from 'react';
import styled from 'styled-components';

import FooterMobileAndSocial from './FooterMobileAndSocial';
import { renderRating } from './Footer';
import {
  ContainerFooter, Content, colors, margins, mobileThresholdPixels,
}
  from '../v2/styledComponents';

import logoTilli from '../../../assets/logos/tilli.white.svg';

const StyledContainerFooter = styled(ContainerFooter)`
  display: flex;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 90px;
  
  @media (max-width: ${mobileThresholdPixels}) {
    padding-top: 30px;
    padding-bottom: 90px;
  }
`;

const StyledContent = styled(Content)`
  width: auto;
  padding: ${margins.x2l} 0px;
  background-color: ${colors.navy};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  @media (max-width: ${mobileThresholdPixels}) {
    width: auto;
    margin: 0px 42px;
  }
`;

const LogoContainer = styled.div`
  width: 225px;
  margin: 40px 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 235px;
  }
`;

const LogoImg = styled.img`
  width: 100%;
  margin-bottom: ${margins.s};
`;

const FooterLightVersion = () => (
  <StyledContainerFooter>
    <StyledContent>
      <LogoContainer>
        <LogoImg src={logoTilli} alt="logo Tilli" />
        {renderRating}
      </LogoContainer>
      <FooterMobileAndSocial smallTopMargin />
    </StyledContent>
  </StyledContainerFooter>
);

export default FooterLightVersion;
