import React from 'react';
import PropTypes from 'prop-types';
import Row2Columns from './Row2Columns';
import RowParagraph from './RowParagraph';
import RowQuote from './RowQuote';
import RowPhoto from './RowPhoto';
import RowPhotos from './RowPhotos';
import RowConclusion from './RowConclusion';
import RowMadeBy from './RowMadeBy';

const Row = (props) => {
  const { layout } = props;
  if (layout === '2_COLUMNS') return <Row2Columns {...props} />;
  if (layout === 'PARAGRAPH') return <RowParagraph {...props} />;
  if (layout === 'QUOTE') return <RowQuote {...props} />;
  if (layout === 'PHOTO') return <RowPhoto {...props} />;
  if (layout === 'PHOTOS') return <RowPhotos {...props} />;
  if (layout === 'CONCLUSION') return <RowConclusion {...props} />;
  if (layout === 'MADE_BY') return <RowMadeBy {...props} />;
  return null;
};

Row.propTypes = {
  layout: PropTypes.string.isRequired,
};

export default Row;
