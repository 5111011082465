import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import {
  colors, Title2, mobileThresholdPixels, fontSizes, margins,
} from '../home/v3/styledComponents';
import Dots from '../home/v3/Dots';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${colors.navy};
  padding: ${margins.m} 0px;
  margin: 22px 0px 50px;
`;

const StyledTitle2 = styled(Title2)`
  margin-bottom: 13px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 0px;
  }
`;

const Content = styled.div`
  display: flex;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 32px;
  width: 400px;
  height: 400px;
  box-shadow: -1px 13px 20px #F0F2F4;
  border-radius: 5px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 20px auto;
    width: 350px;
    height: 350px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.navy};
`;

const PostImg = styled.img`
  width: 350px;
  height: 250px;
  object-fit: cover;
  margin: 37px 23px 23px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 30px auto;
    width: 300px;
    height: 200px;
  }
`;

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 20px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 10px;
  }
`;

const PostCategory = styled.span`
  font-family: Libre Baskerville;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  text-align: center;
  margin-bottom: ${margins.xs};

  @media (max-width: ${mobileThresholdPixels}) {
      font-weight: ${fontSizes.m};
  }
`;

const PostTitle = styled.span`
  font-family: Roboto;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
      font-weight: ${fontSizes.xs};
  }
`;

const LaRubriqueSimilarPosts = ({ similarPosts }) => (
  <Container>
    <StyledTitle2>Articles similaires</StyledTitle2>
    <Dots marginBottom />
    <Content>
      {similarPosts.map((post) => (
        <PostContainer key={post.path}>
          <StyledLink to={post.path}>
            <PostImg src={post.listImage.publicURL} alt={post.listImageAlt} />
            <Overlay>
              <PostCategory>{post.typeOfWork}</PostCategory>
              <PostTitle>{post.title}</PostTitle>
            </Overlay>
          </StyledLink>
        </PostContainer>
      ))}
    </Content>
  </Container>
);

LaRubriqueSimilarPosts.propTypes = {
  similarPosts: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    listImage: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }).isRequired,
    listImageAlt: PropTypes.string.isRequired,
    typeOfWork: PropTypes.string.isRequired,
  })).isRequired,
};

export default LaRubriqueSimilarPosts;
