import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, mobileThresholdPixels } from '../../home/v3/styledComponents';

const RelativeContainer = styled.div`
  position: relative;
`;

const Photo = styled.img`
  width: 100%;
  height: 600px;
  object-fit: cover;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100% - 44px);
    height: 337px;
    margin: 0px 22px;
  }
`;

const Source = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
  color: ${colors.white};
  font-family: Libre Baskerville;
  font-size: 12px;
  line-height: 18px;

  @media (max-width: ${mobileThresholdPixels}) {
    right: 30px;
  }
`;

const RowPhoto = ({ photo: { src, alt, source } }) => (
  <RelativeContainer>
    <Photo src={src.publicURL} alt={alt} loading="lazy" />
    {source ? (
      <Source>
        Source:
        {source}
      </Source>
    ) : null}
  </RelativeContainer>
);

RowPhoto.propTypes = {
  photo: PropTypes.shape({
    src: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }).isRequired,
    alt: PropTypes.string.isRequired,
    source: PropTypes.string,
  }).isRequired,
};

export default RowPhoto;
