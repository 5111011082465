import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import {
  colors, margins, mobileThresholdPixels, contentWidth,
}
  from '../home/v2/styledComponents';
import { toFormattedColoredText } from '../../services/formatting';

const Container = styled.div`
  background-color: ${colors.lightGrey};
  color: ${colors.navy};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${margins.m} 0px;
`;

const Content = styled.div`
  width: ${contentWidth}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    align-items: center;
    width: 100vw;
  }
`;

const keywordsWidth = 300;
const BottomContainer = styled.div`
  width: ${keywordsWidth}px;
  margin-top: ${margins.l};
  color: ${colors.darkGrey};
  margin-left: 22px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.m} 0px 0px;
  }
`;

const KeywordsContainer = styled.div`
  font-size: 14px;
  line-height: 29px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 9px;
    line-height: 15px;
  }
`;

const Keyword = styled.span`
  &:before {
    content:'#';
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImgContainer = styled.div`
  display: flex;
  width: ${contentWidth}px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 90vw;
  }
`;

const Img = styled.img`
  @media (max-width: ${mobileThresholdPixels}) {
    width: 50%;
    object-fit: cover;
  }
`;

const ImgText = styled.span`
  font-size: 45px;
  line-height: 45px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  margin: ${(props) => props.margin};
  @media (max-width: ${mobileThresholdPixels}) {
    width: 50%;
    object-fit: cover;
    font-size: 18px;
    line-height: 18px;
  }
`;

const ImgTextTitle1 = styled(ImgText.withComponent('h2'))`
  font-size: 60px;
  line-height: 74px;
  font-family: Libre Baskerville;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 26px;
    line-height: 34px;
  }
`;

const Title3 = styled.h3`
  text-align: left;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  line-height: 29px;
  ${(props) => !props.margin && 'margin: 0px;'}

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 9px;
    line-height: 15px;
  }
`;

const LaRubriqueTemplate1TopBlock = ({
  keywords, image, imageAlt, imageText, imageText2, style, context: { isMobile },
}) => (
  <Container>
    {!isMobile
      && (
        <Content>
          <Img src={image} alt={imageAlt} />
          <TextContainer>
            <ImgTextTitle1 margin={style.margin} fontSize={style.fontSize}>
              {toFormattedColoredText(imageText, 'yellow')}
            </ImgTextTitle1>
            {imageText2
            && (
              <ImgText margin={style.margin2} fontSize={style.fontSize2}>
                {toFormattedColoredText(imageText2)}
              </ImgText>
            )}
            <BottomContainer>
              <Title3>Mots-clés</Title3>
              <KeywordsContainer>
                {keywords.map((keyword) => <Keyword key={keyword}>{`${keyword} `}</Keyword>)}
              </KeywordsContainer>
            </BottomContainer>
          </TextContainer>
        </Content>
      )}
    {isMobile
      && (
        <Content>
          <ImgContainer>
            <Img src={image} alt={imageAlt} />
            <ImgTextTitle1 margin={style.margin} fontSize={style.fontSize}>
              {toFormattedColoredText(imageText, 'yellow')}
            </ImgTextTitle1>
            {imageText2
            && (
              <ImgText margin={style.margin2} fontSize={style.fontSize2}>
                {toFormattedColoredText(imageText2)}
              </ImgText>
            )}
          </ImgContainer>
          <BottomContainer>
            <Title3>Mots-clés</Title3>
            <KeywordsContainer>
              {keywords.map((keyword) => <Keyword key={keyword}>{`${keyword} `}</Keyword>)}
            </KeywordsContainer>
          </BottomContainer>
        </Content>
      )}
  </Container>
);

LaRubriqueTemplate1TopBlock.propTypes = {
  keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
  image: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  imageText: PropTypes.string.isRequired,
  imageText2: PropTypes.string,
  style: PropTypes.shape({
    fontSize: PropTypes.string.isRequired,
    margin: PropTypes.string.isRequired,
    fontSize2: PropTypes.string,
    margin2: PropTypes.string,
  }).isRequired,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

LaRubriqueTemplate1TopBlock.defaultProps = {
  imageText2: '',
};

export default withAppContext(LaRubriqueTemplate1TopBlock);
