import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';
import { colors, mobileThresholdPixels } from '../../styledComponents';

const blink = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

const DotsContainer = styled.div`
  display: flex;
  ${(props) => props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${(props) => props.marginBottom && 'margin-bottom: 65px;'}
  margin-bottom: ${(props) => props.noMargin ? '0px' : '65px'};
  ${(props) => props.marginTop && `margin-top: ${props.marginTop}px;`}

  @media (max-width: ${mobileThresholdPixels}) {
    ${(props) => props.marginBottom && 'margin-bottom: 36px;'}
    ${(props) => props.noMargin && 'margin-bottom: 0px;'}
  }
`;

const P = styled.p`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  margin: 6px ${(props) => props.spacing / 2}px;
  animation: ${(props) => (props.isAnimated ? css`${blink} 0.6s ease-in infinite alternate` : '')} ;
  ${({ second }) => second && 'animation-delay: 0.2s;'}
  ${({ third }) => third && 'animation-delay: 0.4s;'}
`;

const Dots = ({
  color, marginBottom, marginTop, justifyContent, size, spacing, noMargin, isAnimated,
}) => (
  <DotsContainer marginTop={marginTop} marginBottom={marginBottom} justifyContent={justifyContent} noMargin={noMargin}>
    <P color={color} size={size} spacing={spacing} isAnimated={isAnimated} />
    <P color={color} size={size} spacing={spacing} second={isAnimated} isAnimated={isAnimated} />
    <P color={color} size={size} spacing={spacing} third={isAnimated} isAnimated={isAnimated} />
  </DotsContainer>
);

Dots.propTypes = {
  marginBottom: PropTypes.bool,
  marginTop: PropTypes.number,
  color: PropTypes.string,
  justifyContent: PropTypes.string,
  size: PropTypes.number,
  spacing: PropTypes.number,
  noMargin: PropTypes.bool,
  isAnimated: PropTypes.bool,
};

Dots.defaultProps = {
  marginBottom: false,
  marginTop: undefined,
  color: colors.navy,
  justifyContent: undefined,
  size: 4,
  spacing: 11,
  noMargin: false,
  isAnimated: false,
};

export default Dots;
