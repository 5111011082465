import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Video = styled.video`
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-width: 100%;
`;

const VideoContainer = ({ src }) => (
  <Video
    height="100%"
    width="100%"
    src={src}
    controls
  />
);

VideoContainer.propTypes = {
  src: PropTypes.string.isRequired,
};

export default VideoContainer;
