import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { Content as StyledContent } from '../home/v2/styledComponents';
import {
  DashTextContainer, mobileThresholdPixels, colors, fontSizes, margins,
} from '../home/v3/styledComponents';

const Container = styled.div`
  margin-bottom: 100px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 22px;
  }
`;

const Content = styled(StyledContent)`
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const Title = styled.h2`
  font-family: Libre Baskerville;
  font-size: ${fontSizes.l};
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 1px;
  
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
  }
`;

const LinksContainer = styled.div`
  display: grid;
`;

const Link = styled(GatsbyLink)`
  font-size: ${fontSizes.m};
  font-weight: 500;
  color: ${colors.green};
  margin-bottom: ${margins.s};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
  }

`;

const LaRubriqueInternalLinking = ({ links }) => (
  <Container>
    <Content>
      <DashTextContainer noRightDash>
        <Title>En savoir plus</Title>
      </DashTextContainer>
      <LinksContainer>
        {links.map(({ anchor, link }) => (
          <Link key={link} to={link}>{anchor}</Link>
        ))}
      </LinksContainer>
    </Content>
  </Container>
);

LaRubriqueInternalLinking.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string.isRequired,
    anchor: PropTypes.string.isRequired,
  })).isRequired,
};

export default LaRubriqueInternalLinking;
