import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Breadcrumb from '../LandingPage/Breadcrumb';
import { colors, margins, mobileThresholdPixels } from '../home/v2/styledComponents';
import checkImg from '../../assets/laRubrique/check.svg';
import CTALink from '../CTALink';

const Container = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.lightGrey};

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: ${margins.l};
  }
`;

const Content = styled.div`
  color: ${colors.navy};
  margin-top: 84px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 15px;
  }
`;

const Title = styled.h3`
  color: ${colors.navy};
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  margin: 15px 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.m} 0px;
    font-size: 11px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 60px;
  margin-bottom: 100px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.m} 0px;
  }
`;

const CheckImg = styled.img`
  margin-right: 12px;
  margin-top: 12px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 5px;
    margin-right: 5px;
  }
`;

const P = styled.p`
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 11px;
  }
`;

const Check = (<CheckImg src={checkImg} alt="Check" />);

const LaRubriqueFooter = ({ category, preFilledSteps }) => (
  <Container>
    <Content>
      <Title>{category.footer.title || 'Pas le temps ?'}</Title>
      <P>
        On vous envoie un couturier :
        <br />
        {category.footer.bullets.map((bullet) => (
          <span key={bullet}>
            {Check}
            {bullet}
            <br />
          </span>
        ))}
      </P>
      <ButtonContainer>
        <CTALink fromInteraction="LaRubriqueFooter" defaultLabel="Prendre RDV" linkState={preFilledSteps} />
      </ButtonContainer>
      {(category && category.breadcrumb)
        && <Breadcrumb itemListElement={category.breadcrumb} /> }
    </Content>
  </Container>
);

LaRubriqueFooter.propTypes = {
  category: PropTypes.shape({
    footer: PropTypes.shape({
      title: PropTypes.string.isRequired,
      bullets: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    }).isRequired,
    breadcrumb: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      item: PropTypes.string.isRequired,
    }).isRequired),
  }),
  preFilledSteps: PropTypes.shape({}),
};

LaRubriqueFooter.defaultProps = {
  category: {
    footer: {
      title: 'Pas le temps de vous mettre à la couture ?',
      bullets: [
        'chez vous',
        'qui vous conseille et vous aide à ajuster votre dressing',
        'réalise les retouches dans son atelier',
        'et livre le vêtement après retouches chez vous',
      ],
    },
  },
  preFilledSteps: {},
};

export default LaRubriqueFooter;
