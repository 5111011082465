import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Container, Content } from '../components/home/homeStyledComponents';
import withAppContext from '../withAppContext';
import MenuBar from '../components/home/MenuBar';
import LaRubriquePostTemplate from './LaRubriquePostTemplate';
import LaRubriqueFooter from '../components/LaRubrique/LaRubriqueFooter';
import LaRubriqueInternalLinking from '../components/LaRubrique/LaRubriqueInternalLinking';
import LaRubriqueSimilarPosts from '../components/LaRubrique/LaRubriqueSimilarPosts';
import FooterLightVersion from '../components/home/v3/FooterLightVersion';
import Layout from '../layouts/index';
import { getPreFilledSteps } from '../services/order';
import { renderBecomeTilliste, renderWhyTilli } from '../components/BeTilliste';

const LaRubriquePostTemplateContainer = ({
  data: { laRubriquePostsJson: post },
  pageContext: { similarPosts, internalLinks },
  context: { isMobile },
}) => {
  const preFilledSteps = getPreFilledSteps(
    post.typeOfWork,
    post.categoryOrCloth,
    post.piece,
    post.style,
  );
  return (
    <Layout routeSlug={`LaRubrique_${post.slug}`}>
      <Container>
        <MenuBar />
        <Content>
          <LaRubriquePostTemplate post={post} similarPosts={similarPosts} preFilledSteps={preFilledSteps} />
          {post.slug !== 'CommentDevenirCouturierEn2021'
            ? (
              <LaRubriqueFooter
                category={{
                  footer: post.footer.footerText,
                  breadcrumb: post.footer.breadcrumb,
                }}
                preFilledSteps={preFilledSteps}
              />
            ) : (
              <>
                {renderBecomeTilliste(isMobile)}
                {renderWhyTilli(isMobile)}
              </>
            )}
          <LaRubriqueSimilarPosts similarPosts={similarPosts} />
          {internalLinks.length && <LaRubriqueInternalLinking links={internalLinks} />}
          <FooterLightVersion />
        </Content>
      </Container>
    </Layout>
  );
};

LaRubriquePostTemplateContainer.propTypes = {
  data: PropTypes.shape({
    laRubriquePostsJson: PropTypes.shape({
      mainTitle: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      typeOfWork: PropTypes.string.isRequired,
      categoryOrCloth: PropTypes.string.isRequired,
      piece: PropTypes.string,
      style: PropTypes.string,
      footer: PropTypes.shape({
        footerText: PropTypes.shape({
          title: PropTypes.string.isRequired,
          bullets: PropTypes.arrayOf(PropTypes.string).isRequired,
        }).isRequired,
        breadcrumb: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            item: PropTypes.string.isRequired,
          }).isRequired,
        ),
      }).isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    similarPosts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    internalLinks: PropTypes.arrayOf(PropTypes.shape({
      link: PropTypes.string.isRequired,
      anchor: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(LaRubriquePostTemplateContainer);

// eslint-disable-next-line no-undef
export const postTemplate2Page = graphql`
  query postTemplate2Query($path: String!) {
    laRubriquePostsJson(path: { eq: $path }) {
      id
      slug
      template
      path
      mainTitle
      title
      introduction
      links
      breadcrumb {
        name
        item
      }
      rows {
        layout
        mustReverseBlocksMobile
        blocks {
          type
          src {
            publicURL
          }
          alt
          source
          text
          links
          title {
            text
          }
        }
        paragraph {
          title {
            text
          }
          text
          links
        }
        quote
        photo {
          src {
            publicURL
          }
          alt
          source
        }
        photos {
          src {
            publicURL
          }
          alt
          source
          text
        }
        conclusion
        links
        author
      }
      typeOfWork
      categoryOrCloth
      piece
      style
      keywords
      topImage {
        publicURL
      }
      topImageMobile {
        publicURL
      }
      topImageAlt
      topImageText
      topImageText2
      topImageTextStyle {
        fontSize
        margin
        fontSize2
        margin2
      }
      topImageTextMobileStyle {
        fontSize
        margin
        fontSize2
        margin2
      }
      row1Text
      row1Image {
        publicURL
      }
      row1ImageAlt
      row2Text
      row2Image {
        publicURL
      }
      row2ImageMobile {
        publicURL
      }
      row2ImageAlt
      row2ImageTopMargin
      row2ImageLeftMargin
      p2LeftMargin
      row2ImageText
      row2ImageTextStyle {
        fontSize
        textAlign
        margin
      }
      row2ImageTextMobileStyle {
        fontSize
        textAlign
        margin
      }
      topBlock {
        content {
          mobile {
            marginTopPx
          }
        }
        banner {
          desktop {
            heightPx
            marginTopPx
          }
          mobile {
            heightPx
            marginTopPx
          }
        }
        image {
          src {
            publicURL
          }
          desktop {
            src {
              publicURL
            }
          }
          mobile {
            src {
              publicURL
            }
          }
          alt
          isMobileFull
        }
        title {
          text
          subText
          desktop {
            text
            fontSizePx
            margin
            lineHeightPx
          }
          mobile {
            text
            margin
          }
        }
        lineH {
          desktop {
            margin
            widthPx
          }
          mobile {
            margin
            widthPx
          }
        }
        keywords {
          list
        }
        row1 {
          title
          text {
            text
            desktop {
              margin
            }
            mobile {
              margin
            }
            containerWidthPercent
            links
            isCentered
          }
          image {
            src {
              publicURL
            }
            desktop {
              src {
                publicURL
              }
            }
            mobile {
              src {
                publicURL
              }
            }
            alt
          }
        }
        separatorText
      }
      middleBlock {
        longVersion
        subBlockTitle
        notNumbered
        titleOnLeft
        rows {
          image {
            src {
              publicURL
            }
            srcMobile {
              publicURL
            }
            alt
            caption {
              title
              text
              margin
              marginMobile
              isFlexEnd
            }
            isMobileFull
            margin
            button {
              label
              preFilledStepCloth
            }
            text {
              label
              fontSize
              fontSizeMobile
              margin
              marginMobile
            }
          }
          video {
            src
            width
            height
            margin
            mobileWidth
            mobileHeight
            mobileMargin
          }
          imagesRow {
            src {
              publicURL
            }
            srcMobile {
              publicURL
            }
            alt
            caption {
              title
              text
              margin
              marginMobile
            }
            margin
          }
          textMargin
          textPadding
          title1
          title1Mobile
          title1Margin
          isTitle1Centered
          contentMobileMarginBottom
          text1
          text1IsCentered
          text1Links
          title2
          text2
          textIsRight
          isMobileReversed
          isMobileTitleCentered
          isDesktopTitleHidden
          isMobileTitleHidden
          isMobileTitleInsideBlock
          mobileTextMargin
        }

        adviceBlock {
          adviceList
          closingText
        }
        lastRow {
          image {
            src {
              publicURL
            }
            alt
          }
          text
        }
      }
      anecdote {
        tillisteName
        tillisteWords
        tillisteImg {
          publicURL
        }
      }
      anecdote {
        tillisteName
        tillisteWords
        tillisteImg {
          publicURL
        }
      }
      bottomBlock {
        row4Image {
          src {
            publicURL
          }
          alt
        }
        row4TitleMarginMobile
        row4Text
        row4TextMargin
      }
      madeByText
      footer {
        footerText {
          title
          bullets
        }
        breadcrumb {
          name
          item
        }
      }
    }
  }
`;
