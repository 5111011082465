import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { toFormattedBoldText, toFormattedColoredText } from '../../services/formatting';
import {
  colors, margins, fontSizes, mobileThresholdPixels, Button,
} from '../home/v3/styledComponents';
import routesMap from '../../Routes';

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ margin }) => margin && `margin: ${margin};`}
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
  }
`;

const CaptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ isFlexEnd }) => isFlexEnd && 'align-items: flex-end;'}
  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${({ marginMobile }) => marginMobile || '15px 0px 0px 0px'};
  }
`;

const CaptionText = styled.div`
  font-family: Roboto;
  font-size: ${({ isTitle }) => (isTitle ? 18 : 14)}px;
  line-height: ${({ isTitle }) => (isTitle ? 25 : 20)}px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 10px;
    line-height: 14px;
    ${({ marginMobile }) => !marginMobile && 'margin-left: 12%'};
  }
`;

const Img = styled.img`
  @media (max-width: ${mobileThresholdPixels}) {
    width: ${({ isMobileFull }) => (isMobileFull ? 100 : 90)}vw;
    object-fit: cover;
    margin: 10px auto 0px;
  }
`;

const TextContainer = styled.div`
  flex: 1;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 90vw;
    ${({ noMobileMarginTop }) => !noMobileMarginTop && 'margin-top: 38px;'}
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`}
  }
`;

const PContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ isFull }) => (isFull ? 'center' : 'flex-end')};
  ${({ isFull }) => isFull
    && `
    width: fit-content;
    margin: auto;
  `}
`;

const P = styled.div`
  display: flex;
  font-size: 18px;
  line-height: 25px;
  flex-direction: ${(prop) => (prop.directionColumn ? 'column' : 'row')};
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  background-color: ${colors.lightGrey};
  padding: ${({ padding }) => padding || '50px'};
  margin: ${(prop) => (prop.marginAuto ? `${margins.m} auto` : margins.m)};
  ${(prop) => prop.margin && `margin: ${prop.margin};`}
  max-width: ${({ isFull }) => (!isFull ? '800' : '1080')}px;
  ${({ isFull }) => isFull && 'box-sizing: content-box;'}
  ${({ textAlignCenter }) => textAlignCenter && 'text-align: center;'}

  @media (max-width: ${mobileThresholdPixels}) {
    height: auto;
    padding: 25px 20px;
    font-size: 14px;
    line-height: 20px;
    margin: 0px;
    ${({ isMobileTitleInsideBlock }) => isMobileTitleInsideBlock && 'padding-top: 80px;'}
  }
`;

const ParagraphTitle = styled.span`
  align-self: flex-start;
  font-size: ${({ isCentered }) => isCentered ? `${fontSizes.x3l}` : `${fontSizes.x2l}`};
  font-weight: 500;
  color: ${(prop) => (prop.yellow ? colors.yellow : colors.navy)};
  margin: ${(prop) => prop.margin || '0px 0px -50px 60px'};
  ${(prop) => prop.smallLeftMargin && 'margin-left: 25px'};
  z-index: 1;
  ${({ isCentered }) => isCentered && 'text-align: center;'};
  ${({ isDesktopTitleHidden }) => isDesktopTitleHidden && 'display:none;'};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${({ isCentered }) => isCentered ? '0px 0px 33px' : '0px 0px -15px 20px'};
    font-size: ${fontSizes.xl};
    ${({ isCentered }) => isCentered && 'align-self: center; font-size: 20px;'};
    ${({ isMobileTitleCentered }) => isMobileTitleCentered
      && `
      margin: 0px 0px 28px 0px;
      align-self: center;
      text-align: center;
      font-size: 20px;
    `};
    ${({ isMobileTitleInsideBlock }) => isMobileTitleInsideBlock
      && `
      font-size: 15px;
      margin-bottom: -60px;
    `};
    ${({ isDesktopTitleHidden }) => isDesktopTitleHidden && 'display:block;'};
    ${({ isMobileTitleHidden }) => isMobileTitleHidden && 'display:none;'};
  }
`;

const Ol = styled.ol`
  padding-inline-start: 20px;
`;

const VideoContainer = styled.div`
  ${({ margin }) => margin && `margin: ${margin};`}
`;

const IFrame = styled.iframe`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const ButtonContainer = styled.div`
  width: fit-content;
  margin-top: 30px;
  @media (max-width: ${mobileThresholdPixels}) {
    align-self: center;
  }
`;

const ImgText = styled.div`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: ${({ fontSize }) => fontSize};
  color: ${colors.navy};
  position: absolute;
  margin: ${({ margin }) => margin};
`;

const ImagesRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const Block = (
  title,
  text,
  titleMargin,
  textMargin,
  textPadding,
  links,
  isMobileTitleInsideBlock,
  isMobileTitleCentered,
  isDesktopTitleHidden,
  isMobileTitleHidden,
  isTitle1Centered,
  isText1Centered,
) => (
  <PContainer isFull={!title || !text}>
    {title && (
      <ParagraphTitle
        margin={titleMargin}
        isCentered={!text || isTitle1Centered}
        isMobileTitleInsideBlock={isMobileTitleInsideBlock}
        isMobileTitleCentered={isMobileTitleCentered}
        isDesktopTitleHidden={isDesktopTitleHidden}
        isMobileTitleHidden={isMobileTitleHidden}
      >
        {toFormattedColoredText(title, 'yellow')}
      </ParagraphTitle>
    )}
    {text && (
      <P
        margin={textMargin}
        padding={textPadding}
        isFull={!title}
        isMobileTitleInsideBlock={isMobileTitleInsideBlock}
        textAlignCenter={isText1Centered}
      >
        <span>
          {toFormattedBoldText(text, 500, links, { fontWeight: '500' })}
        </span>
      </P>
    )}
  </PContainer>
);

const AdviceBlock = (list, closingText) => (
  <PContainer>
    <ParagraphTitle yellow smallLeftMargin>
      Conseil dépannage ...
    </ParagraphTitle>
    <P directionColumn marginAuto>
      <Ol>
        {list.map((step) => (
          <li key={step}>
            {' '}
            {step}
          </li>
        ))}
      </Ol>
      <span>{closingText}</span>
    </P>
  </PContainer>
);

const renderImg = (isMobile, row) => (
  <Img
    src={
      isMobile && row.image?.srcMobile
        ? row.image.srcMobile.publicURL
        : row.image.src.publicURL
    }
    alt={row.image.alt}
    isMobileFull={row.image.isMobileFull}
    loading="lazy"
  />
);

const renderImgText = (isMobile, row) => (
  <div>
    <ImgText
      fontSize={
        isMobile
          ? row.image.text.fontSizeMobile
          : row.image.text.fontSize
      }
      margin={
        isMobile
          ? row.image.text.marginMobile
          : row.image.text.margin
      }
    >
      {row.image.text.label}
    </ImgText>
  </div>
);

const renderImgCaption = (row) => (
  <CaptionContainer
    margin={row.image.caption.margin}
    marginMobile={row.image.caption.marginMobile}
    isFlexEnd={row.image.caption.isFlexEnd}
  >
    <CaptionText isTitle marginMobile={row.image.caption.marginMobile}>
      {toFormattedBoldText(row.image.caption.title, 500)}
    </CaptionText>
    {row.image.caption.text && (
      <CaptionText marginMobile={row.image.caption.marginMobile}>
        {toFormattedBoldText(row.image.caption.text, 500)}
      </CaptionText>
    )}
    {row.image.button && (
      <ButtonContainer>
        <Link
          to={routesMap.Step1Summary.url}
          state={{
            preFilledCloth: row.image.button.preFilledStepCloth,
          }}
        >
          <Button navy>{row.image.button.label}</Button>
        </Link>
      </ButtonContainer>
    )}
  </CaptionContainer>
);

const renderImgContainer = (isMobile, row) => (
  <ImageContainer margin={row.image.margin}>
    {renderImg(isMobile, row)}
    {!!row?.image?.text && renderImgText(isMobile, row)}
    {row?.image?.caption && renderImgCaption(row)}
  </ImageContainer>
);

const renderVideoContainer = (isMobile, row) => (
  <VideoContainer
    margin={isMobile ? row.video.mobileMargin : row.video.margin}
  >
    <IFrame
      width={isMobile ? row.video.mobileWidth : row.video.width}
      height={isMobile ? row.video.mobileHeight : row.video.height}
      src={row.video.src}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </VideoContainer>
);

const renderTextContainer = (isMobile, row) => (
  <TextContainer
    isFull={!row.title1 || !row.text1}
    noMobileMarginTop={!row?.image?.src && !row?.image?.srcMobile}
    mobileMargin={row.mobileTextMargin}
  >
    {Block(
      isMobile && row.title1Mobile ? row.title1Mobile : row.title1,
      row.text1,
      row.title1Margin,
      row.textMargin,
      row.textPadding,
      row.text1Links,
      row.isMobileTitleInsideBlock,
      row.isMobileTitleCentered,
      row.isDesktopTitleHidden,
      row.isMobileTitleHidden,
      row.isTitle1Centered,
      row.text1IsCentered,
    )}
  </TextContainer>
);

const renderImagesRow = (isMobile, row) => (
  <ImagesRow>
    {row.imagesRow.map(({
      src, srcMobile, alt, margin, caption,
    }) => (
      <ImageContainer margin={margin} key={src.publicURL}>
        <Img
          src={isMobile ? srcMobile.publicURL : src.publicURL}
          alt={alt}
          loading="lazy"
        />
        {caption && (
          <CaptionContainer
            margin={caption.margin}
            marginMobile={caption.marginMobile}
          >
            <CaptionText isTitle>
              {toFormattedBoldText(caption.title, 500)}
            </CaptionText>
            {caption.text && (
              <CaptionText>
                {toFormattedBoldText(caption.text, 500)}
              </CaptionText>
            )}
          </CaptionContainer>
        )}
      </ImageContainer>
    ))}
  </ImagesRow>
);

export {
  Block, AdviceBlock, renderImgContainer, renderVideoContainer, renderTextContainer, renderImagesRow,
};
