import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import {
  colors, margins, mobileThresholdPixels, contentWidth,
} from '../home/v3/styledComponents';
import {
  Block, AdviceBlock, renderImgContainer, renderVideoContainer, renderTextContainer, renderImagesRow,
} from './laRubriqueTemplate2MiddleBlockLongVersionComponents';
import CTALink from '../CTALink';

const Container = styled.div`
  color: ${colors.navy};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${margins.m} 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    padding-top: 0px;
  }
`;

const Content = styled.div`
  width: ${contentWidth}px;
  display: flex;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  justify-content: center;
  align-items: center;
  margin-bottom: ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: ${({ noMobileMarginTop }) => (noMobileMarginTop ? 0 : 76)}px;
    margin-bottom: ${({ noMobileMarginTop }) => noMobileMarginTop ? '33px' : `${margins.l}`};
    ${({ negativeMobileMarginBottom }) => negativeMobileMarginBottom && 'margin-bottom: -80px;'};
    flex-direction: ${({ isMobileReversed }) => isMobileReversed ? 'column-reverse' : 'column'};
    align-items: center;
    width: 100vw;
    ${({ mobileMarginBottom }) => mobileMarginBottom && `margin-bottom: ${mobileMarginBottom};`}
  }
`;

const Img = styled.img`
  @media (max-width: ${mobileThresholdPixels}) {
    width: ${({ isMobileFull }) => (isMobileFull ? 100 : 90)}vw;
    object-fit: cover;
    margin: 10px auto 0px;
  }
`;

const TextContainer = styled.div`
  flex: 1;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 90vw;
    ${({ noMobileMarginTop }) => !noMobileMarginTop && 'margin-top: 38px;'}
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`}
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-bottom: 40px;
  justify-content: center;
`;

const LaRubriqueTemplate2MiddleBlockLongVersion = ({
  rows,
  adviceBlock,
  lastRow,
  context: { isMobile },
  preFilledSteps,
}) => (
  <Container>
    {rows.map((row, index) => {
      const hasImage = !!row?.image?.srcMobile || !!row?.image?.src;
      return (
        <React.Fragment key={`${row.isDesktopTitleHidden ? '1' : '2'} ${row.title1 || row.text1}`}>
          {index === 2 && (
            <ButtonContainer>
              <CTALink fromInteraction="LaRubriqueFooter" defaultLabel="Prendre RDV" linkState={preFilledSteps} />
            </ButtonContainer>
          )}
          <Content
            reverse={!row.textIsRight}
            noMobileMarginTop={!row.text1 || !hasImage}
            negativeMobileMarginBottom={row.title1 && !row.text1 && !hasImage}
            isMobileReversed={row.isMobileReversed}
            mobileMarginBottom={row.contentMobileMarginBottom}
          >
            {(row?.image?.src || (isMobile && row?.image?.srcMobile)) && (
              renderImgContainer(isMobile, row)
            )}
            {row?.video?.src && renderVideoContainer(isMobile, row)}
            {(row.title1 || row.text1) && renderTextContainer(isMobile, row)}
            {row.imagesRow && renderImagesRow(isMobile, row)}
          </Content>
        </React.Fragment>
      );
    })}
    {adviceBlock && (
      <Content>
        {AdviceBlock(adviceBlock.adviceList, adviceBlock.closingText)}
      </Content>
    )}
    {lastRow && (
      <Content>
        <Img src={lastRow.image.src.publicURL} alt={lastRow.image.alt} loading="lazy" />
        <TextContainer>{Block('', lastRow.text)}</TextContainer>
      </Content>
    )}
  </Container>
);

LaRubriqueTemplate2MiddleBlockLongVersion.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        src: PropTypes.shape({ publicURL: PropTypes.string }),
        srcMobile: PropTypes.shape({ publicURL: PropTypes.string }),
        margin: PropTypes.string,
        alt: PropTypes.string,
        caption: PropTypes.shape({
          title: PropTypes.string,
          text: PropTypes.string,
          margin: PropTypes.string,
          marginMobile: PropTypes.string,
          isFlexEnd: PropTypes.bool,
        }),
        preFilledStepCloth: PropTypes.string,
        isMobileFull: PropTypes.bool,
        text: PropTypes.shape({
          label: PropTypes.string,
          fontSize: PropTypes.string,
          fontSizeMobile: PropTypes.string,
          margin: PropTypes.string,
          marginMobile: PropTypes.string,
        }),
      }),
      video: PropTypes.shape({
        src: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
        margin: PropTypes.string,
        mobileWidth: PropTypes.string,
        mobileHeight: PropTypes.string,
        mobileMargin: PropTypes.string,
      }),
      textMargin: PropTypes.string,
      textPadding: PropTypes.string,
      title1: PropTypes.string,
      title1Mobile: PropTypes.string,
      title1Margin: PropTypes.string,
      title1MarginBottomMobile: PropTypes.string,
      isTitle1Centered: PropTypes.bool,
      contentMobileMarginBottom: PropTypes.string,
      text1: PropTypes.string,
      text1Links: PropTypes.arrayOf(PropTypes.string),
      text1IsCentered: PropTypes.bool,
      textIsRight: PropTypes.bool,
      isMobileReversed: PropTypes.bool,
      isMobileTitleCentered: PropTypes.bool,
      isDesktopTitleHidden: PropTypes.bool,
      isMobileTitleHidden: PropTypes.bool,
      isMobileTitleInsideBlock: PropTypes.bool,
      mobileTextMargin: PropTypes.string,
      imagesRow: PropTypes.arrayOf(
        PropTypes.shape({
          src: PropTypes.shape({ publicURL: PropTypes.string }),
          srcMobile: PropTypes.shape({ publicURL: PropTypes.string }),
          alt: PropTypes.string,
          caption: PropTypes.shape({
            title: PropTypes.string,
            text: PropTypes.string,
            margin: PropTypes.string,
            marginMobile: PropTypes.string,
          }),
          margin: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  adviceBlock: PropTypes.shape({
    adviceList: PropTypes.arrayOf(PropTypes.string.isRequired),
    closingText: PropTypes.string.isRequired,
  }),
  lastRow: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }),
      alt: PropTypes.string.isRequired,
    }),
    text: PropTypes.string.isRequired,
  }),
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  preFilledSteps: PropTypes.shape({}),
};

LaRubriqueTemplate2MiddleBlockLongVersion.defaultProps = {
  adviceBlock: undefined,
  lastRow: undefined,
  preFilledSteps: {},
};

export default withAppContext(LaRubriqueTemplate2MiddleBlockLongVersion);
