import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { colors, fontSizes } from '../home/v2/styledComponents';

const Container = styled.div`
  margin: 0px;
`;

const Ol = styled.ol`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 0px;
  margin-top: 0px;
`;

const LiContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Span = styled.span`
  font-family: Roboto;
  font-size: ${fontSizes.s};
  font-weight: ${(prop) => prop.fontWeightLight ? 300 : 500};
  text-align: center;
  color: ${colors.navy};
`;

const Separator = styled(Span)`
  margin: 0px 5px;
`;

const Li = styled.li`text-align: center;`;

const Breadcrumb = ({ lightVersion, itemListElement }) => (
  <Container>
    <Ol vocab="http://schema.org" typeof="BreadcrumbList">
      {itemListElement.map((element, index) => (
        <LiContainer key={element.item}>
          <Li property="itemListElement" typeof="ListItem">
            <Link
              property="item"
              typeof="WebPage"
              to={element.item}
              style={{ textDecoration: 'none', color: `${colors.navy}` }}
            >
              <Span fontWeightLight={lightVersion} property="name">{element.name}</Span>
            </Link>
            <meta property="position" content={index + 1} />
          </Li>
          {index !== itemListElement.length - 1 && <Separator>›</Separator>}
        </LiContainer>
      ))}
    </Ol>
  </Container>
);

Breadcrumb.propTypes = {
  itemListElement: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    item: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  lightVersion: PropTypes.bool,
};

Breadcrumb.defaultProps = {
  lightVersion: false,
};

export default Breadcrumb;
