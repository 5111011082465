import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Video from './Video';
import { mobileThresholdPixels } from '../../home/v3/styledComponents';

const Container = styled.div`
  width: 440px;
  height: 100%;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const BlockVideo = ({ src }) => (
  <Container>
    <Video src={src.publicURL} />
  </Container>
);

BlockVideo.propTypes = {
  src: PropTypes.shape({
    publicURL: PropTypes.string.isRequired,
  }).isRequired,
};

export default BlockVideo;
