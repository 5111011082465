import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { toFormattedText } from '../../services/formatting';
import {
  colors, margins, mobileThresholdPixels, fontSizes,
}
  from '../home/v2/styledComponents';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.navy};
  padding: ${margins.m} 0px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    align-items: center;
  }
`;

const Img1 = styled.img`
  @media (max-width: ${mobileThresholdPixels}) {
    width: 90vw;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  padding: ${margins.m};
  margin: ${(props) => props.margin};
  width: 40%;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
    width: 90vw;
    margin-left: 1px;
    margin-top: 50px;
  }
`;

const Title = styled.p`
  font-family: Libre Baskerville;
  font-weight: 500;
  font-size: 48px;
  align-self: flex-start;
  margin: 0px 0px -22px 37px;
  z-index: 2;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 22px;
    margin: 0px 0px -11px 20px;
  }
`;

const P = styled.p`
  z-index: 1;
  line-height: 25px;
  background-color: ${colors.lightGrey};
  padding: 40px;
  margin: 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: auto;
    text-align: left;
    font-size: ${fontSizes.s};
    line-height: 20px;
    padding: 30px 20px;
  }
`;

const LaRubriqueTemplate2BottomBlock = ({
  row4Image, row4ImageAlt, row4Text, row4TextMargin,
}) => (
  <Container>
    <Content>
      <Img1 src={row4Image} alt={row4ImageAlt} loading="lazy" />
      <TextContainer margin={row4TextMargin}>
        <Title>En bref ...</Title>
        <P>{toFormattedText(row4Text)}</P>
      </TextContainer>
    </Content>
  </Container>
);

LaRubriqueTemplate2BottomBlock.propTypes = {
  row4Image: PropTypes.string.isRequired,
  row4ImageAlt: PropTypes.string.isRequired,
  row4Text: PropTypes.string.isRequired,
  row4TextMargin: PropTypes.string.isRequired,
};

export default LaRubriqueTemplate2BottomBlock;
