import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  colors, margins, fontSizes, mobileThresholdPixels, contentWidth,
}
  from '../home/v2/styledComponents';

const Container = styled.div`
  color: ${colors.navy};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${margins.m} 0px;
`;

const Content = styled.div`
  width: ${contentWidth}px;
  display: flex;
  flex-direction: ${(props) => props.reverse ? 'row-reverse' : 'row'};
  justify-content: center;
  align-items: center;
  margin-bottom: ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: ${margins.l};
    flex-wrap: wrap;
    align-items: center;
    width: 100vw;
  }
`;

const TitleContainer = styled.div`
  text-align: center;
  margin: 0px 0px ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 0px;
  }
`;

const Title3 = styled.h3`
  font-family: Libre Baskerville;
  font-size: ${fontSizes.x2l};
  white-space: pre;
  font-weight: 500;
  margin: 0px 0px 10px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.xl};
    margin-bottom: -15px;
  }
`;

const SubTitle3 = styled.span`
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
  }
`;

const ImgContainer = styled.div`
  display: flex;

  @media (max-width: ${mobileThresholdPixels}) {
    display: initial;
    text-align: center;
  }
`;

const Img = styled.img`
  z-index: 1;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 90%;
    margin: ${margins.m} auto ${margins.l};
  }
`;

const TextContainer = styled.div`
  width: 50%;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const PContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(prop) => prop.alignEnd ? 'flex-end' : 'flex-start'};
`;

const P = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  background-color: ${colors.lightGrey};
  padding: ${margins.m};
  margin: ${margins.m};
  font-size: 18px;
  line-height: 25px;

  @media (max-width: ${mobileThresholdPixels}) {
    height: auto;
    margin: ${margins.m} 0px;
    font-size: 14px;
    line-height: 20px;
  }
`;

const ParagraphTitle = styled.span`
  font-size: ${fontSizes.x2l};
  font-weight: 500;
  color: ${colors.yellow};
  margin: ${(prop) => prop.alignEnd ? '0px 50px -48px 0px' : '0px 50px -48px 7%'};
  z-index: 1;
  line-height: 1;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${(prop) => prop.alignEnd ? '0px auto -48px 15px' : '0px 15px -48px auto'};
  }
`;

const NumberedSpan = styled.span`
  font-weight: 500;
  font-size: ${fontSizes.x4l};
  color: ${colors.yellow};
  margin: -50px 15px 0px -15px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 36px;
  }
`;

const NotNumberedP = (title, text, titleOnLeft) => (
  <PContainer alignEnd={!titleOnLeft}>
    {title && <ParagraphTitle alignEnd={!titleOnLeft}>{title}</ParagraphTitle>}
    <P>
      <span>{text}</span>
    </P>
  </PContainer>
);

const NumberedP = (index, title, text, titleOnLeft) => (
  <PContainer alignEnd={!titleOnLeft}>
    {title && <ParagraphTitle alignEnd={!titleOnLeft}>{title}</ParagraphTitle>}
    <P>
      <NumberedSpan>{`${index}.`}</NumberedSpan>
      <span>{text}</span>
    </P>
  </PContainer>
);

const LaRubriqueTemplate2MiddleBlock = ({
  subBlockTitle, notNumbered, titleOnLeft, rows,
}) => (
  <Container>
    <TitleContainer>
      <Title3>Instructions</Title3>
      {subBlockTitle && <SubTitle3>{subBlockTitle}</SubTitle3>}
    </TitleContainer>
    {rows.map((row, index) => (
      <Content reverse={index % 2 !== 0} key={row.text1}>
        <ImgContainer>
          <Img
            src={row.image.src.publicURL}
            alt={row.image.alt}
            loading="lazy"
          />
        </ImgContainer>
        {notNumbered
          ? (
            <TextContainer>
              {NotNumberedP(row.title1, row.text1, titleOnLeft)}
              {NotNumberedP(row.title2, row.text2, titleOnLeft)}
            </TextContainer>
          )
          : (
            <TextContainer>
              {NumberedP((index * 2) + 1, row.title1, row.text1, titleOnLeft)}
              {NumberedP((index + 1) * 2, row.title2, row.text2, titleOnLeft)}
            </TextContainer>
          )}
      </Content>
    ))}
  </Container>
);

LaRubriqueTemplate2MiddleBlock.propTypes = {
  subBlockTitle: PropTypes.string,
  notNumbered: PropTypes.bool,
  titleOnLeft: PropTypes.bool,
  rows: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.shape({ publicURL: PropTypes.string }).isRequired,
      alt: PropTypes.string.isRequired,
    }).isRequired,
    title1: PropTypes.string,
    text1: PropTypes.string.isRequired,
    title2: PropTypes.string,
    text2: PropTypes.string.isRequired,
  })).isRequired,
};

LaRubriqueTemplate2MiddleBlock.defaultProps = {
  subBlockTitle: undefined,
  notNumbered: false,
  titleOnLeft: false,
};

export default LaRubriqueTemplate2MiddleBlock;
