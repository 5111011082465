import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BlockText from './BlockText';
import BlockPhoto from './BlockPhoto';
import BlockVideo from './BlockVideo';
import BlockHighlight from './BlockHighlight';
import { mobileThresholdPixels } from '../../home/v3/styledComponents';

const Container = styled.div`
  margin-left: 44px;
  margin-right: 44px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-left: 22px;
    margin-right: 22px;
    margin-top: ${({ isFirst, mustReverseMobile }) => {
    if (mustReverseMobile) return !isFirst ? 0 : 30;
    return isFirst ? 0 : 30;
  }}px;
  }
`;

const blockTypes = {
  TEXT: { component: BlockText, getKey: (props) => props.text },
  PHOTO: { component: BlockPhoto, getKey: (props) => props.src.publicURL },
  VIDEO: { component: BlockVideo, getKey: (props) => props.src.publicURL },
  HIGHLIGHT: { component: BlockHighlight, getKey: (props) => props.highlight },
};

const Block = (props) => {
  const { type, index, mustReverseMobile } = props;
  const Component = blockTypes[type].component;
  return (
    <Container isFirst={index === 0} mustReverseMobile={mustReverseMobile}>
      <Component {...props} />
    </Container>
  );
};

Block.getKey = (props) => {
  const { type } = props;
  return blockTypes[type].getKey(props);
};

Block.propTypes = {
  type: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  mustReverseMobile: PropTypes.bool,
};

Block.defaultProps = {
  mustReverseMobile: false,
};

export default Block;
