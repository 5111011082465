import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Block from './Block';
import { mobileThresholdPixels } from '../../home/v3/styledComponents';

const Container = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: ${({ mustReverseMobile }) => mustReverseMobile ? 'column-reverse' : 'column'};
  }
`;

const Row2Columns = ({ mustReverseBlocksMobile, blocks }) => (
  <Container mustReverseMobile={mustReverseBlocksMobile}>
    {blocks?.map((block, index) => <Block {...block} index={index} key={Block.getKey(block)} mustReverseMobile={mustReverseBlocksMobile} />)}
  </Container>
);

Row2Columns.propTypes = {
  mustReverseBlocksMobile: PropTypes.bool,
  blocks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Row2Columns.defaultProps = {
  mustReverseBlocksMobile: false,
};

export default Row2Columns;
