import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import {
  colors, margins, fontSizes, mobileThresholdPixels, contentWidth, LineH,
}
  from '../home/v2/styledComponents';
import { toFormattedColoredText, toFormattedBoldText } from '../../services/formatting';

const Container = styled.div`
  color: ${colors.navy};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${margins.m} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
  }
`;

const Content = styled.div`
  width: ${contentWidth}px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: ${margins.xl};
  margin-top: 80px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 0px;
    align-items: center;
    width: 100vw;
    flex-direction: column;
    margin-bottom: ${({ noMobileMarginBottom }) => noMobileMarginBottom ? '0px' : margins.l};
    ${({ mobileMarginTopPx }) => mobileMarginTopPx && `margin-top: ${mobileMarginTopPx}px;`}
  }
`;

const Img1 = styled.img`
  z-index: 1;
  margin-top: -60px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: ${({ isMobileFull }) => isMobileFull ? 100 : 90}vw;
    height: auto;
    margin-top: 20px;
  }
`;

const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImgTextContainer = styled.div`
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: -40px;
  }
`;

const GreyBanner = styled.div`
  background-color: ${colors.lightGrey};
  left: 0px;
  width: 200vw;
  margin-left: -1500px;
  height: ${({ heightPx }) => heightPx ?? 270}px;
  margin-top: ${({ marginTopPx }) => marginTopPx ?? -30}px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100vw;
    margin-left: 0px;
  }
`;

const ImgText = styled.h2`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: Libre Baskerville;
  font-weight: 500;
  width: fit-content;
  font-size: ${({ fontSizePx }) => fontSizePx}px;
  height: ${({ heightPx }) => heightPx}px;
  line-height: ${({ lineHeightPx }) => lineHeightPx || 74}px;
  margin: ${({ margin, heightPx }) => margin || `-${heightPx}px 0px 0px -15px`};
  ${({ noUpperCase }) => !noUpperCase && 'text-transform: uppercase;'}
  ${({ hasSubText, alignCenter }) => (hasSubText || alignCenter) && 'width: fit-content; align-items: center;'}
  ${({ alignCenter }) => alignCenter && 'text-align: center;'}

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 26px;
    line-height: 34px;
    margin-left: ${({ hasSubText, alignCenter }) => (hasSubText || alignCenter) ? 0 : 22}px;
    ${({ hasSubText, alignCenter }) => (hasSubText || alignCenter) && 'width: 100%;'}
  }
`;

const StyledSpan = styled.span`
  z-index: 1;
  display: flex;
  flex-direction: column;
  ${({ isSubText }) => isSubText && 'font-family: Roboto; font-size: 40px; margin-top: 10px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    ${({ isSubText }) => isSubText && 'font-size: 10px;'}
  }
`;

const StyledLineH = styled(LineH).attrs({ focus: true })`
  z-index: 1;
  width: ${({ widthPx }) => widthPx}px;
  margin: ${({ margin }) => margin};
`;

const KeywordsBlockContainer = styled.div`
  font-family: Roboto;
  font-size: ${fontSizes.s};
  line-height: 29px;
  color: ${colors.darkGrey};
  width: 275px;
  margin-left: 22px;
  margin-top: 10px;
  z-index: 1;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 9px;
    line-height: 15px;
    width: 85vw;
    margin-left: 30px;
    margin-top: 15px;
  }
`;

const Keyword = styled.span`
  &:before {
    content:'#';
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column-reverse;
  }
`;

const Img2 = styled.img`
  @media (max-width: ${mobileThresholdPixels}) {
    width: 90vw;
  }
`;

const TextContainer = styled.div`
  background-color: ${colors.lightGrey};
  width: ${({ widthPercent }) => widthPercent || 60}%;
  margin: ${({ margin }) => margin || '0px'};
  z-index: 1;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 90vw;
    margin: ${({ margin }) => margin || '33px 0px 0px'};
  }
`;

const SeparatorTextContainer = styled(TextContainer)`
  margin: auto;
  padding: 30px;
`;

const ParagraphTitle = styled.span`
  align-self: flex-start;
  font-size: ${fontSizes.x2l};
  font-weight: 500;
  margin: -23px 0px 0px 50px;
  position: absolute;
  z-index: 1;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: -16px 0px 0px 20px;
    font-size: ${fontSizes.xl};
  }
`;

const P = styled.p`
  margin: 0px;
  padding: 50px;
  z-index: 2;
  font-size: 18px;
  line-height: 25px;
  ${({ textAlignCenter }) => textAlignCenter && 'text-align: center;'}

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    line-height: 20px;
    margin: 25px 20px;
    padding: 0px;
  }
`;

const LaRubriqueTemplate2TopBlock = ({
  context: { isMobile }, content, banner, image, title, lineH, keywords, row1, separatorText,
}) => {
  const context = isMobile ? 'mobile' : 'desktop';
  return (
    <Container>
      <Content mobileMarginTopPx={content?.mobile?.marginTopPx}>
        <Img1 src={image.src?.publicURL || image[context].src.publicURL} alt={image.alt} isMobileFull={image.isMobileFull} />
        <HeaderTextContainer>
          <ImgTextContainer>
            <GreyBanner heightPx={banner[context].heightPx} marginTopPx={banner[context].marginTopPx} />
            <ImgText
              fontSizePx={title[context].fontSizePx}
              lineHeightPx={title[context].lineHeightPx}
              heightPx={banner[context].heightPx}
              noUpperCase={!keywords}
              margin={title[context].margin}
              hasSubText={!!title.subText}
              alignCenter={!keywords}
            >
              <StyledSpan>{toFormattedColoredText(title.text || title[context].text, 'yellow')}</StyledSpan>
              {!!title.subText && <StyledSpan isSubText>{title.subText}</StyledSpan>}
              <StyledLineH margin={lineH[context].margin} widthPx={lineH[context].widthPx} />
            </ImgText>
          </ImgTextContainer>
          {keywords && (
            <KeywordsBlockContainer>
              <div>
                Mots-clés
                {' '}
                <br />
                {keywords.list.map((keyword) => (
                  <Keyword key={keyword}>
                    {keyword}
                    {' '}
                  </Keyword>
                ))}
              </div>
            </KeywordsBlockContainer>
          )}
        </HeaderTextContainer>
      </Content>
      <Content noMobileMarginBottom={!!separatorText}>
        <Row>
          <TextContainer margin={row1.text[context]?.margin} widthPercent={row1.text.containerWidthPercent}>
            {row1.title && <ParagraphTitle>{toFormattedColoredText(row1.title)}</ParagraphTitle>}
            <P textAlignCenter={row1.text.isCentered}>
              {toFormattedBoldText(row1.text.text, 500, row1.text.links, { fontWeight: 500 })}
            </P>
          </TextContainer>
          {row1.image && (<Img2 src={row1.image.src?.publicURL || row1.image[context].src.publicURL} alt={row1.image.alt} />)}
        </Row>
      </Content>
      {separatorText
        && (
          <Content noMobileMarginBottom>
            <SeparatorTextContainer>{separatorText}</SeparatorTextContainer>
          </Content>
        )}
    </Container>
  );
};

LaRubriqueTemplate2TopBlock.propTypes = {
  content: PropTypes.shape({
    mobile: PropTypes.shape({
      marginTopPx: PropTypes.number,
    }),
  }),
  banner: PropTypes.shape({
    desktop: PropTypes.shape({
      heightPx: PropTypes.number,
      marginTopPx: PropTypes.number,
    }),
    mobile: PropTypes.shape({
      heightPx: PropTypes.number,
      marginTopPx: PropTypes.number,
    }),
  }).isRequired,
  image: PropTypes.shape({
    src: PropTypes.shape({
      publicURL: PropTypes.string,
    }),
    desktop: PropTypes.shape({
      src: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
    }),
    mobile: PropTypes.shape({
      src: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
    }),
    alt: PropTypes.string,
    isMobileFull: PropTypes.bool,
  }).isRequired,
  lineH: PropTypes.shape({
    desktop: PropTypes.shape({
      margin: PropTypes.string,
      widthPx: PropTypes.number,
    }),
    mobile: PropTypes.shape({
      margin: PropTypes.string,
      widthPx: PropTypes.number,
    }),
  }).isRequired,
  title: PropTypes.shape({
    text: PropTypes.string,
    subText: PropTypes.string,
    desktop: PropTypes.shape({
      text: PropTypes.string,
      fontSizePx: PropTypes.number,
      margin: PropTypes.string,
      lineHeightPx: PropTypes.number,
    }),
    mobile: PropTypes.shape({
      text: PropTypes.string,
      margin: PropTypes.string,
    }),
  }).isRequired,
  keywords: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.string),
  }),
  row1: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.shape({
      text: PropTypes.string,
      isCentered: PropTypes.bool,
      links: PropTypes.arrayOf(PropTypes.string),
      desktop: PropTypes.shape({
        margin: PropTypes.string,
      }),
      mobile: PropTypes.shape({
        margin: PropTypes.string,
      }),
      containerWidthPercent: PropTypes.number,
    }),
    image: PropTypes.shape({
      src: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
      desktop: PropTypes.shape({
        src: PropTypes.shape({
          publicURL: PropTypes.string,
        }),
      }),
      mobile: PropTypes.shape({
        src: PropTypes.shape({
          publicURL: PropTypes.string,
        }),
      }),
      alt: PropTypes.string,
    }),
  }).isRequired,
  separatorText: PropTypes.string,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

LaRubriqueTemplate2TopBlock.defaultProps = {
  content: undefined,
  keywords: undefined,
  separatorText: undefined,
};

export default withAppContext(LaRubriqueTemplate2TopBlock);
